// @ts-nocheck
import React, { useMemo, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import oopsImg from './oops.jpg'
import { CircularProgress, Stack } from '@mui/material'
import { isValidHttpUrl } from 'utils/utilFuntions'

function AppVideoPlayer ({
  videoId,
  fallbackIMGWidth = '460px',
  fallbackIMGHeight = 'auto',
  playerHeight = 'auto',
  playerWidth = '100%'
} : Props) {
  const url = useMemo(() => {
    const isUrl = isValidHttpUrl(videoId);
    if(isUrl)
      return videoId;
    return `https://vimeo.com/${videoId}`
  },[videoId]);
  const [error, setError] = useState(false)
  const [playerReady, setPlayerReady] = useState(false)

  const handlePlayerReady = () => {
    setPlayerReady(true)
  }
  const handleError = () => {
    setError(true)
  }

  return (
    <div>
      {error
        ? (
            <img width={fallbackIMGWidth} alt='Error' height={fallbackIMGHeight} src={oopsImg} />
          )
        : (
          <>
            <ReactPlayer
              allowFullScreen
              width={playerWidth}
              height={playerHeight}
              style={{
                display: playerReady ? 'block' : 'none'
              }}
              url={url}
              controls={true}
              fallback={
                <img width={fallbackIMGWidth} alt='Video Player' height={fallbackIMGHeight} src={oopsImg} />
              }
              onReady={handlePlayerReady}
              onError={(error) => handleError(error)}
            />
            {
              !playerReady && (
              <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
              </Stack>
              )
            }
          </>
          )}
    </div>
  )
}

interface Props {
  videoId: string
  fallbackIMGWidth?: string
  fallbackIMGHeight?: string
  playerHeight?: string
  playerWidth?: string
}

export default AppVideoPlayer
