import { Stack, TextField, Typography, Link } from '@mui/material'
import { Paper } from 'components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TimerIcon from '@mui/icons-material/Timer'
import LockIcon from '@mui/icons-material/Lock'
import { api } from 'Core'
import useMockedUser from 'hooks/useMockedUser'
import { useLocation, useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'
import InputMask from 'react-input-mask'
import jwtUtil from 'Core/utils/jwtUtil'

function MfaSetup () {
  const { email } = useMockedUser()
  const { t } = useTranslation()
  const [phone, setPhone] = useState<null | string>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<null | string>(null)
  const { state } = useLocation()
  const navigate = useNavigate()
  const userRoles = jwtUtil.getRoles();
  const is2faBypass = jwtUtil.check2faBypass();

  const handleSubmit = async (e : React.FormEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      const data = await api.portalAuth.send2FaOtp({
        type: 'sms',
        overrideCheck: true,
        phone,
        email
      })
      if (data?.success) {
        return navigate(`../confirm/${data?.token}`, { state: { phone, action: 'add' } })
      } else {
        setError('Something went wrong')
      }
    } catch (e) {
      setError('Something went wrong')
    }

    setLoading(false)
  }

  const handleChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '')
    setPhone(onlyNums)
  }

  return (
    <Stack gap={3}>
        <Typography variant='h4' component='h1' sx={{ textAlign: 'center' }}>
          {state?.action === 'update' ? t('authenticationinfo.mfa.titleupdate') : t('authenticationinfo.mfa.title')}
        </Typography>
        <Paper>
          <Stack gap={2} component='form' onSubmit={handleSubmit} >
            <Typography variant='h6' component='b' sx={{ fontWeight: 700 }}>{ state?.action === 'update' ? t('authenticationinfo.mfa.heading1update') : t('authenticationinfo.mfa.heading1')}</Typography>
            { state && state?.action === 'update'
              ? null
              : (
              <>
                <Stack flexDirection={'row'} gap={1}>
                  <TimerIcon/>
                  <Typography>
                    <Typography component='b' sx={{ fontWeight: 700 }}>{t('authenticationinfo.mfa.subheading1')}</Typography>
                    {t('authenticationinfo.mfa.subBody1')}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'} gap={1}>
                  <LockIcon/>
                  <Typography>
                    <Typography component='b' sx={{ fontWeight: 700 }}>{t('authenticationinfo.mfa.subheading2')}</Typography>
                    {t('authenticationinfo.mfa.subBody2')}
                  </Typography>
                </Stack>
              </>
                ) }
            <Typography>{t('authenticationinfo.mfa.body1')}</Typography>
            <InputMask
              mask="(999) 999-9999"
              maskChar=""
              value={ phone || '' }
              onChange={ (e) => handleChange(e) }
              id={ 'phone' }
            >
              {/* @ts-ignore */ }
              { () => (
                <TextField
                  name='phone'
                  label={t('authenticationinfo.mfa.hint1')}
                  inputProps={{ maxLength: 14 }}
                  error={!!error}
                  helperText={error}
                  fullWidth
                  required
                  FormHelperTextProps={ {
                    sx: { color: 'primary.red' }
                  } }
                  sx={ {
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: 'inherit',
                      WebkitTextFillColor: '#212121'
                    }
                  } }
                />
              ) }
            </InputMask>
            <LoadingButton variant='contained'
              disabled={ phone?.length !== 10 }
              loading={loading}
              type='submit'
              fullWidth
            >{t('authenticationinfo.mfa.button1')}</LoadingButton>
            { userRoles.length && !is2faBypass
              ? null
              : (
                <Link
                  component={RouterLink}
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginLeft: 2
                  }}
                  onClick={() => {
                    localStorage.setItem(`mfa_setup_snooz_${email}`, moment().add(10, 'days').format())
                  }}
                  to='/'
                >
                  {t('authenticationinfo.mfa.link1')}
                </Link>
              ) 
            }
          </Stack>
        </Paper>
    </Stack>
  )
}

export default MfaSetup
