import React, { useRef, useState } from 'react'
import { CompositeDecorator, convertFromRaw, Editor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded'
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded'
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded'
import StrikethroughSRoundedIcon from '@mui/icons-material/StrikethroughSRounded'
import ListRoundedIcon from '@mui/icons-material/ListRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import {
  Box,
  Divider,
  Stack,
  Link as MuiLink,
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent
} from '@mui/material'
import useFormik from 'hooks/useFormik'
import { OpenInNew } from '@mui/icons-material'

function Link (
  /** @type {{contentState: import('draft-js').ContentState, entityKey: string, children: React.ReactNode }} */
  { contentState, entityKey, children }
) {
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <MuiLink href={url} target='_blank' referrerPolicy='no-referrer'>
      {children}
    </MuiLink>
  )
}

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
])

function findLinkEntities (contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity(decorator)
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

export function createEditorStateFromRaw (rawState) {
  return createEditorState(rawState ? convertFromRaw(rawState) : null)
}

export function createEditorState (initialEditorState = null) {
  return initialEditorState
    ? EditorState.createWithContent(initialEditorState, decorator)
    : EditorState.createEmpty(decorator)
}

export function isTextEmptyOrJustWhiteSpaces (rawState) {
  const textFound = rawState.blocks.find((line) => line.text)
  if (textFound) return false
  return true
}

function RichTextEditor (
  /** @type {{ editorState: import('draft-js').EditorState, onChange?: (editorState: EditorState) => void, readOnly?: boolean }} */
  { onChange = null, editorState, readOnly = false }
) {
  const [linkFormOpen, setLinkFormOpen] = useState(false)

  const handleEditorStateChange = newState => {
    onChange && onChange(newState)
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      handleEditorStateChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const toggleInlineStyle = inlineStyle => {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle)
    handleEditorStateChange(newState)
  }

  const toggleBlockType = blockType => {
    const newState = RichUtils.toggleBlockType(editorState, blockType)
    handleEditorStateChange(newState)
  }

  const checkInlineStyleActive = inlineStyle =>
    editorState.getCurrentInlineStyle().has(inlineStyle)

  const checkBlockTypeActive = blockTypeToCheck => {
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()

    return blockType === blockTypeToCheck
  }

  const getInlineStyleButtonProps = (
    inlineStyle,
    overrides = { icon: null }
  ) => ({
    active: checkInlineStyleActive(inlineStyle),
    styleName: inlineStyle,
    onToggle: toggleInlineStyle,
    ...overrides
  })

  const getBlockTypeStyleButtonProps = (
    blockType,
    overrides = { icon: null }
  ) => ({
    active: checkBlockTypeActive(blockType),
    styleName: blockType,
    onToggle: toggleBlockType,
    ...overrides
  })

  const [link, setLink] = useState({ url: '', text: '' })

  const editorRef = useRef(null)

  const focusEditor = () => {
    if (editorRef.current) editorRef.current.focus()
  }

  const promptForLink = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      let url = ''
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
        const anchorKey = selection.getAnchorKey()
        const endOffset = selection.getEndOffset()
        const currentContentBlock = contentState.getBlockForKey(anchorKey)
        const text = currentContentBlock.getText().slice(startOffset, endOffset)
        setLink({
          url,
          text
        })
      }

      setLinkFormOpen(true)
    }
  }

  const confirmLink = values => {
    const { url } = values
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    })
    const updatedState = RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    )

    onChange && onChange(updatedState)

    setLinkFormOpen(false)

    setTimeout(focusEditor, 0)
  }

  const handleLinkDialogClose = () => {
    setLinkFormOpen(false)
    focusEditor()
  }

  return (
    <Stack sx={{ backgroundColor: readOnly ? 'transparent' : '#F7F7F7', overflowX: 'hidden' }}>
      {!readOnly
        ? (
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 2,
              px: 1,
              py: 1.5,
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)'
            }}
            divider={<Divider sx={{ borderWidth: '1px' }} />}
          >
            <StylesWrap>
              <StyleButton
                {...getInlineStyleButtonProps('BOLD', {
                  icon: FormatBoldRoundedIcon
                })}
              />
              <StyleButton
                {...getInlineStyleButtonProps('ITALIC', {
                  icon: FormatItalicRoundedIcon
                })}
              />
              <StyleButton
                {...getInlineStyleButtonProps('UNDERLINE', {
                  icon: FormatUnderlinedRoundedIcon
                })}
              />
              <StyleButton
                {...getInlineStyleButtonProps('STRIKETHROUGH', {
                  icon: StrikethroughSRoundedIcon
                })}
              />
            </StylesWrap>
            <StylesWrap>
              <StyleButton
                {...getBlockTypeStyleButtonProps('ordered-list-item', {
                  icon: FormatListNumberedRoundedIcon
                })}
              />
              <StyleButton
                {...getBlockTypeStyleButtonProps('unordered-list-item', {
                  icon: ListRoundedIcon
                })}
              />
            </StylesWrap>
            <StylesWrap>
              <Stack sx={styleButtonWrapStyle} onClick={promptForLink}>
                <LinkRoundedIcon sx={{ fontSize: 18 }} />
              </Stack>
            </StylesWrap>
          </Stack>
          )
        : null}
      <Box
        sx={{
          p: readOnly ? 0 : 1.5,
          fontSize: readOnly ? 16 : 14,
          '& .public-DraftEditor-content': {
            minHeight: readOnly ? 0 : 120
          }
        }}
      >
        <Editor
          readOnly={readOnly}
          customStyleMap={{ CODE: { minHeight: 200 } }}
          editorState={editorState}
          onChange={handleEditorStateChange}
          handleKeyCommand={handleKeyCommand}
          spellCheck
        />
      </Box>
      <Dialog open={linkFormOpen} onClose={handleLinkDialogClose}>
        <LinkForm
          onSubmit={confirmLink}
          onClose={handleLinkDialogClose}
          initialValues={link}
        />
      </Dialog>
    </Stack>
  )
}

function LinkForm ({ onSubmit, onClose, initialValues }) {
  const formik = useFormik({
    initialValues,
    onSubmit
  })

  return (
    <>
      <DialogTitle>Add Link</DialogTitle>
      <DialogContent>
        <Stack component='form' sx={{ gap: 2 }} onSubmit={formik.handleSubmit}>
          <TextField
            label='Link'
            name='url'
            onChange={formik.handleChange}
            value={formik.values.url}
          />
          {formik.values.url
            ? (
              <Button
                variant='contained'
                component={MuiLink}
                href={formik.values.url}
                target='_blank'
                referrerPolicy='no-referrer'
              >
                Open <OpenInNew sx={{ fontSize: 16 }} />
              </Button>
              )
            : null}
          <Stack sx={{ flexDirection: 'row', gap: 2, ml: 'auto' }}>
            <Button variant='contained' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type='submit' variant='contained'>
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  )
}

const styleButtonWrapStyle = {
  height: 24,
  width: 24,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: 0.5
}

function StyleButton ({ styleName, active, onToggle, icon: Icon, ...props }) {
  return (
    <Stack
      component='span'
      sx={{
        backgroundColor: active ? '#00000014' : 'transparent',
        ...styleButtonWrapStyle
      }}
      onMouseDown={e => {
        e.preventDefault()
        onToggle(styleName)
      }}
      role='button'
      {...props}
    >
      <Icon sx={{ fontSize: 18 }} />
    </Stack>
  )
}

function StylesWrap ({ children, sx = {} }) {
  return <Stack sx={{ flexDirection: 'row', gap: 2, ...sx }}>{children}</Stack>
}

export default RichTextEditor
