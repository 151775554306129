import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { Route, Routes, useParams } from 'react-router-dom'
import { MultiStepForm, TRoute } from 'components/MultiStepForm'
import { Container, Paper, SHKPoweredByLogo } from 'components'
import Home from './Home'
import About from './About'
import PatientSelection from './PatientSelection'
import StiInfo from './StiInfo'
import LifestyleInfo from './LifeStyleInfo'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import invariant from 'tiny-invariant'
import AdditionalQuestions from './AdditionalQuestions'
import useAuth0 from 'hooks/useAuth0'
import AuthPage from './AuthPage'
import InsuranceInfo from './InsuranceInfo'
import HeaderPrepProgram from './components-prep/HeaderPrepProgram'
import EnrollComplete from './EnrollComplete'
import type { TPrepProgram } from './schemas'
import * as schemas from './schemas'
import PharmacySelection from './PharmacySelection'

const routes: TRoute<TPrepProgram>[] = [
  {
    path: ''
  },
  {
    path: 'lets-get-started'
  },
  {
    path: 'patient-info'
  },
  {
    path: 'lifestyle-info'
  },
  {
    path: 'sti-info'
  },
  {
    path: 'additional-questions'
  },
  {
    path: 'insurance-info'
  },
  {
    path: 'pharmacy',
  }
]

function useGetPrepProgramConfig() {
  const { slug } = useParams()
  invariant( slug, 'slug is missing' )

  return useQuery(
    [ 'prep-config', slug ],
    () => api.program.getPrepProgramConfig( slug ),
    { select: response => response.site }
  )
}

function getDefaultData() {
  const sessionCache = window.sessionStorage.getItem( 'prep-program' )
  if ( !sessionCache ) return schemas.prepProgram.getDefault()
  return JSON.parse( sessionCache ) as TPrepProgram
}

export function PrepProgram() {
  const [ data, setData ] = useState<TPrepProgram>( getDefaultData )
  const { user } = useAuth0()

  const { isLoading, isError, data: site } = useGetPrepProgramConfig()

  if ( isLoading ) return <Typography>Loading...</Typography>
  if ( isError ) return <Typography>Error fetching prep config!</Typography>

  const prepProgramConfig = site.prep_program_config

  function handleChange( updatedData: TPrepProgram ) {
    window.sessionStorage.setItem( 'prep-program', JSON.stringify( updatedData ) )
    setData( updatedData )
  }

  return (
    <>
      <HeaderPrepProgram prepProgramConfig={prepProgramConfig} />
      <Container>
        <Stack sx={{ gap: 3, alignItems: 'center' }}>
          <Stack sx={{ textAlign: 'center', gap: 1 }}>
            <Typography variant='h4' component='h1'>
              Enroll In A Program
            </Typography>
            <SHKPoweredByLogo />
          </Stack>
          <Paper
            sx={{
              width: {
                xs: '100%',
                sm: 560
              }
            }}
          >
            {user ? (
              <MultiStepForm
                data={data}
                onChange={handleChange}
                routes={routes}
              >
                <Routes>
                  <Route
                    index
                    element={<Home prepProgramConfig={prepProgramConfig} />}
                  />
                  <Route
                    path={routes[ 1 ].path}
                    element={<About prepProgramConfig={prepProgramConfig} />}
                  />
                  <Route
                    path={routes[ 2 ].path}
                    element={<PatientSelection site={site} />}
                  />
                  <Route
                    path={routes[ 3 ].path}
                    element={
                      <LifestyleInfo prepProgramConfig={prepProgramConfig} />
                    }
                  />
                  <Route
                    path={routes[ 4 ].path}
                    element={<StiInfo prepProgramConfig={prepProgramConfig} />}
                  />
                  <Route
                    path={routes[ 5 ].path}
                    element={
                      <AdditionalQuestions
                        prepProgramConfig={prepProgramConfig}
                      />
                    }
                  />
                  <Route
                    path={routes[ 6 ].path}
                    element={
                      <InsuranceInfo prepProgramConfig={prepProgramConfig} />
                    }
                  />
                  <Route
                    path={routes[ 7 ].path}
                    element={
                      <PharmacySelection prepProgramConfig={prepProgramConfig} site={site}/>
                    }
                  />
                  <Route
                    path='enroll-complete'
                    element={
                      <EnrollComplete prepProgramConfig={prepProgramConfig} />
                    }
                  />
                </Routes>
              </MultiStepForm>
            ) : (
              <AuthPage siteDetails={site} />
            )}
          </Paper>
        </Stack>
      </Container>
    </>
  )
}

export function Heading( { children }: { children: React.ReactNode } ) {
  return (
    <Typography variant='h5' component='h2'>
      {children}
    </Typography>
  )
}
