import React, { useEffect } from 'react'
import {
  Box,
  Stack,
  Modal,
  Typography,
  Link as MuiLink,
  Button,
  useMediaQuery
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import theme from 'theme'
import DOMPurify from 'dompurify'
import { currentPageName } from 'hooks/useAnalytics'
import useAuth0 from 'hooks/useAuth0'
import { analyticEventTracker } from 'utils/analytics'

const ReviewModalComponent = ({
  reviewModal,
  setOpenReviewModal,
  productLink,
  meta = {}
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useAuth0()

  useEffect(() => {
    if (reviewModal) {
      analyticEventTracker('(E) Product Review Modal Open', {
        category: 'Product Review Modal',
        page: currentPageName(window?.location?.pathname),
        data: {
          isMobile,
          email: user?.email || 'N/A'
        },
        meta
      })
    }
  }, [reviewModal])

  const userActionClicked = () => {
    analyticEventTracker('(E) Product Review Modal Clicked', {
      category: 'Product Review Modal',
      page: currentPageName(window?.location?.pathname),
      data: {
        isMobile,
        email: user?.email || 'N/A'
      },
      meta
    })
    setOpenReviewModal(false)
  }

  return (
    <Modal
      open={reviewModal}
      onClose={() => setOpenReviewModal(false)}
      aria-labelledby="modal-modal-subject"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          background: '#FFF',
          display: 'flex',
          flexFlow: 'column',
          gap: '1rem',
          maxHeight: '90%',
          overflowY: 'auto',
          maxWidth: '40rem',
          padding: '2rem',
          position: 'relative',
          width: '100%'
        }}
      >
        <Stack
          component="form"
          autoComplete="off"
          sx={{ gap: '1rem', width: '100%' }}
        >
          <Typography variant="h6">{t('productLink.feedback.title')}</Typography>
          <Typography variant="body1">
            {t('productLink.feedback.body1')}
          </Typography>
          <Stack flexDirection={isMobile ? 'column' : 'row'}>
            <Typography variant="body1"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t('productLink.feedback.body2'))
              }}
            />
            <Stack
              component='img'
              src={ '/assets/product_feedback.svg' }
              alt={ 'Submit Product Feedback' }
              width={200}
            />
          </Stack>
          <MuiLink target="_blank" rel="noreferrer" href={productLink}>
            <Button
              onClick={userActionClicked}
              variant="contained"
              fullWidth
            >
              {t('productLink.feedback.button1')}
            </Button>
          </MuiLink>
          <MuiLink sx={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }} onClick={() => setOpenReviewModal(false)}>
            {t('productLink.feedback.button2')}
          </MuiLink>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ReviewModalComponent
