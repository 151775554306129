import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { store } from 'state/store'
import ClientSideError from 'pages/ErrorPage/ClientSideError'
import React from 'react'

import {
  Provider as RollbarProvider,
  ErrorBoundary,
  LEVEL_ERROR
} from '@rollbar/react'
import { NetworkStatus } from 'Core/utils/NetworkStatus'
import SnackBar from 'components/SnackBar'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import TnCModal from 'components/TnCModal'

const rollbarEnvs = {
  'local.com:3000': 'app-shkdev-local-react',
  'shkdev.link': 'app-shkdev-link-react',
  'shkdev.org': 'app-shkdev-org-react',
  'shkdemo.me': 'app-shk-shkdemo-me-react',
  'simplehealthkit.com': 'app-simplehealthkit-com-react'
}

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  // @ts-ignore
  environment: rollbarEnvs[process.env.REACT_APP_DOMAIN] ? rollbarEnvs[process.env.REACT_APP_DOMAIN] : 'app-shkdev-link-react',
  maxItems: 10,
  // @ts-ignore
  checkIgnore: function (_isUncaught, args, payload) {
    if (
      payload &&
      ['app-shkdev-local-react', 'app-shkdev-link-react'].includes(payload.environment)
    ) {
      return true
    }

    if (
      args[0] &&
      args[0].message &&
      args[0].message.includes('Loading chunk')
    ) {
      return true
    }
    return false
  }
}

// @ts-ignore
const ErrorDisplay = ({ error }) => {
  if (error) {
    console.error(error)
  }
  return <ClientSideError />
}

// @ts-ignore
function AppProvider ({ children }) {
  const whiteLabelConfig = useWhiteLabelConfig()

  rollbarConfig.environment =
    whiteLabelConfig?.variables?.appDomain || process.env.REACT_APP_DOMAIN

  return (
    <Provider store={store}>
      <RollbarProvider config={rollbarConfig}>
        <SnackbarProvider
          maxSnack={10}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <ErrorBoundary level={LEVEL_ERROR} fallbackUI={ErrorDisplay}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <NetworkStatus />
              <SnackBar />
              <TnCModal />
              {children}
            </StyledEngineProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      </RollbarProvider>
    </Provider>
  )
}

export default AppProvider
