function restfulBuilder (name, { singleton = false }) {
  return function (state = null, action) {
    if (!state) state = singleton ? {} : []
    let payload = action.payload
    if (action.type === `${name}/add`) {
      if (singleton) {
        state = Object.assign({}, payload)
        return state
      }
      let newState = [...state]
      if (action.deleteFirst) {
        newState = []
      }
      if (!Array.isArray(payload)) payload = [payload]
      // {123: {..}, 234: {..}}
      payload = payload.reduce((acc, item) => {
        // REST items require an id
        // so that the keys doesn't auto sort or we can use map instead
        acc[` ${item.id}`] = item
        return acc
      }, {})

      const newIds = Object.keys(payload).map(id => (`${id}`))
      newState = newState.map(item => {
        if (newIds.indexOf(` ${item.id}`) >= 0) {
          const newItem = payload[` ${item.id}`]
          newIds.splice(newIds.indexOf(` ${item.id}`), 1)
          return newItem
        }
        return item
      })
      for (const i in newIds) {
        newState.push(payload[`${newIds[i]}`])
      }
      return newState
    } else if (action.type === `${name}/update`) {
      const newState = [...state]
      const index = newState.findIndex((st) => st.id === payload.id)
      // newState.splice(index, 1, {...newState[index], ...payload})
      newState.splice(index, 1, payload)
      return newState
    } else if (action.type === `${name}/remove`) {
      const newState = [...state]
      const index = newState.findIndex((st) => st.id === payload.id)
      if (index > -1) {
        newState.splice(index, 1)
      }
      return newState
    }
    return state
  }
}

function uiBuilder (initialState = {}) {
  return function (state = null, action) {
    if (!state) state = initialState
    const m = action.type.match(/^ui\/(.+)$/)
    if (!m) return state
    const newState = { ...state }
    const [, name] = m
    newState[name] = action.payload
    return newState
  }
}

function uiAction (name, payload) {
  const op = payload ? 'set' : 'clear'
  return {
    type: `ui/${name}/${op}`,
    payload
  }
}

export { restfulBuilder, uiBuilder, uiAction }
