export const queryKeys = {
  sites: {
    ALL: 'ALL_SITES',
    ACCESS_SITES: 'ACCESS_SITES',
    SITE: 'SITE',
    INSURANCE: 'INSURANCE'
  },
  analytics: {
    PROGRAM_INSIGHTS: 'PROGRAM_INSIGHTS',
    POPULATION_HEALTH: 'POPULATION_HEALTH',
    OPERATIONS: 'OPERATIONS',
    PROGRAM_INSIGHTS_DOWNLOAD: 'PROGRAM_INSIGHTS_DOWNLOAD',
    POPULATION_HEALTH_DOWNLOAD: 'POPULATION_HEALTH__DOWNLOAD',
    HEALTH_PLAN_DOWNLOAD_RAW_DATA: 'HEALTH_PLAN_DOWNLOAD_RAW_DATA'
  },
  results: {
    instructionsToUpload: 'INSTRUCTIONS_TO_UPLOAD'
  },
  ftp: {
    content: 'FTP_CONTENT'
  },
  healthPlan: {
    resultsDeliveryReport: 'RESULTS_DELIVERY_REPORT'
  },
  i18nextContent: {
    ALL: 'I18NEXT_CONTENT_GET_ALL',
    ONE: 'I18NEXT_CONTENT_GET_ONE',
    MULTIPLE: 'I18NEXT_CONTENT_GET_MULTIPLE'
  },
  analyteConfigs: {
    ONE: 'ANALYTE_CONFIG_GET_ONE',
    ALL: 'ANALYTE_CONFIG_GET_ALL'
  },
  mailerSettings: {
    ALL: 'MAILER_SETTINGS_GET_ALL',
    ONE: 'MAILER_SETTINGS_GET_ONE',
    CREATE: 'MAILER_SETTINGS_CREATE',
    UPDATE: 'MAILER_SETTINGS_UPDATE',
    DELETE: 'MAILER_SETTINGS_DELETE'
  },
  ssoSettings: {
    ALL: 'SSO_SETTINGS_GET_ALL',
    ONE: 'SSO_SETTINGS_GET_ONE',
    CREATE: 'SSO_SETTINGS_CREATE',
    UPDATE: 'SSO_SETTINGS_UPDATE',
    DELETE: 'SSO_SETTINGS_DELETE'
  },
  skus: {
    ALL: 'SKUS_GET_ALL',
    ONE: 'SKUS_GET_ONE'
  },
  mailAndFax: {
    mailsOnHold: 'MAILS_ON_HOLD',
    faxesOnHold: 'FAXES_ON_HOLD'
  }
}
