import React, { useEffect, useState } from 'react'
import { OpenInNew } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useFormik } from 'formik'
import Loader from 'components/Loader'
import { api } from 'Core'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { Container, Paper } from 'components'
import PasswordPolicy from 'components/PasswordPolicy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Link as RouterLink } from 'react-router-dom'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import TermsPolicy from 'components/TestResult/components/TermsPolicy'

const validationSchema = (t) => yup.object({
  terms: yup.boolean().oneOf([true], t('signup.error.acceptTerms')),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      t('resetPassword.error.strongPassword')
    )
    .required(t('resetPassword.error.password')),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], t('resetPassword.error.passwordMatch')).required(t('resetPassword.error.confirmPassword'))
})

export const ResetPasswordFrom = ({
  formik,
  showPassword,
  toggleShowPassword,
  showConfirmPassword,
  toggleShowConfirmPassword,
  personalData,
  type = 1,
  email = ''
}) => {
  const whiteLabelConfig = useWhiteLabelConfig()
  const { t } = useTranslation()

  return (
    <>
      <Stack sx={{ alignItems: 'center' }}>
        <Typography variant='h4' component='h1' mb={1}>
          {t(type === 1 ? 'resetPassword.title' : 'resetPassword.Changetitle')}
        </Typography>
      </Stack>
      <Typography variant='subtitle1' mb={1}>
        { whiteLabelConfig.isActive ? t('resetPassword.desc.whiteLabel') : t(type === 1 ? 'resetPassword.desc.shk' : 'resetPassword.desc.shkChange', { email }) }
      </Typography>
      <Stack gap={2} py={1} component='form' onSubmit={formik.handleSubmit} autoComplete='off'>
        <TextField
          name='password'
          type={showPassword ? 'text' : 'password'}
          label={t('resetpassword.formFields.password')}
          onChange={formik.handleChange}
          value={formik.values.password}
          helperText={
            formik.touched.password &&
            Boolean(formik.errors.password) &&
            formik.errors.password
          }
          error={formik.touched.password && Boolean(formik.errors.password)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            )
          }}
        />

        <TextField
          name='confirmPassword'
          type={showConfirmPassword ? 'text' : 'password'}
          label={t('resetpassword.formFields.confirmPassword')}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          helperText={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword) &&
            formik.errors.confirmPassword
          }
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowConfirmPassword}>
                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            )
          }}
        />

        <PasswordPolicy context={formik.values} policy={personalData} />

        <Stack>
          <FormControl
            error={!!formik.errors.terms}
            sx={{ textAlign: 'start' }}
          >
            <FormControlLabel
              sx={{ borderRadius: '0 0 5px 5px' }}
              control={
                <Checkbox
                  name='terms'
                  checked={formik.values.terms || false}
                  onChange={formik.handleChange}
                />
              }
              label={
                <Typography>
                    <TermsPolicy/>
                </Typography>
              }
            />
            <FormHelperText>{String(t(formik.errors.terms) || '')}</FormHelperText>
          </FormControl>
        </Stack>

        <Stack my={1}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={formik.isSubmitting}
          >
            {t('resetPassword.button.text')}
          </LoadingButton>
        </Stack>
      </Stack>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row'
          // alignItems: 'center'
        }}
      >
        <Typography mx={0.5} variant='body2' fontWeight={500}>
          {whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions)
            ? ''
            : (
              <>
                <Link
                  href={whiteLabelConfig.isActive ? whiteLabelConfig?.terms_conditions || '' : 'https://www.simplehealthkit.com/terms'}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('login.termsAndCondition')} <OpenInNew fontSize='inherit' />
                </Link>
                {' | '}
              </>
              )
          }
          { whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.privacy_policy)
            ? ''
            : (
              <>
                <Link
                  href={whiteLabelConfig.isActive ? whiteLabelConfig?.privacy_policy || '' : 'https://www.simplehealthkit.com/privacy'}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('footer.privacyPolicy')} <OpenInNew fontSize='inherit' />
                </Link>
              </>
              )
          }
        </Typography>
      </Box> */}
    </>
  )
}

const ResetPassword = () => {
  const whiteLabelConfig = useWhiteLabelConfig()
  const { t } = useTranslation()
  const [isValid, setValid] = useState(false)
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [personalData, setPersonalData] = useState('')
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () =>
    setShowPassword(showPassword => !showPassword)

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(showConfirmPassword => !showConfirmPassword)

  const checkTicket = async () => {
    try {
      const url = new URL(window.location.href)
      const parsed = url.searchParams.get('ticket')
      if (parsed) {
        setToken(parsed)
        const isTicketValid = await api.auth.checkTicket(parsed)
        if (isTicketValid.success) {
          setValid(true)
        } else {
          setValid(false)
        }
      } else {
        setValid(false)
      }
    } catch (error) {
      dispatch(
        showSnackBar({
          show: true,
          message: 'Something went wrong',
          severity: 'error'
        })
      )
      setValid(false)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    checkTicket()
  }, [])

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      terms: false
    },
    validationSchema: validationSchema(t),
    onSubmit: async (values, { setErrors }) => {
      try {
        const response = await api.auth.changePassword({
          password: values.password,
          token
        })
        if (response.success) {
          dispatch(
            showSnackBar({
              show: true,
              severity: 'success',
              message: 'Password changed successfully'
            })
          )
          setPasswordChanged(true)
        } else {
          dispatch(
            showSnackBar({
              show: true,
              message: 'Something went wrong',
              severity: 'error'
            })
          )
          if (response.message === 'personal-data') {
            setErrors({ password: 'Password contains personal data' })
          }
          setPersonalData(response.message)
        }
      } catch (error) {
        dispatch(
          showSnackBar({
            show: true,
            message: 'Something went wrong',
            severity: 'error'
          })
        )
      }
    }
  })

  if (loading) return <Loader />

  let component = null

  if (!passwordChanged) {
    component = <ResetPasswordFrom
      formik={formik}
      showPassword={showPassword}
      toggleShowPassword={toggleShowPassword}
      showConfirmPassword={showConfirmPassword}
      toggleShowConfirmPassword={toggleShowConfirmPassword}
      personalData={personalData}
    />
  } else {
    component = (
      <Stack gap={2} display='flex' flexDirection='column' alignItems='center'>
        <CheckCircleOutlinedIcon sx={{ fontSize: '64px ' }} color='success' />
        <Typography variant='h5' component='h1'>Password Changed!</Typography>
        <Typography variant='body1'>
          Your password has been changed successfully.
        </Typography>
        <Button
          variant='contained'
          LinkComponent={RouterLink}
          to='/'
        >
          Back to Portal
        </Button>
      </Stack>
    )
  }

  return (
    <Container>
      <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Paper sx={{ width: 'min(100%, 600px)' }}>
          {isValid
            ? (
                component
              )
            : (
              <Stack
                gap={2}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <CancelOutlinedIcon sx={{ fontSize: '64px ' }} color='error' />
                <Typography variant='h6' component='h1'>
                  This link is invalid.
                </Typography>
                <Typography variant='body1'>
                  To reset your password, return to the login page and select
                  &quot;Forgot Your Password&quot; to send a new email.
                </Typography>
                <Button
                  variant='contained'
                  data-testid='backToPortalButton'
                  LinkComponent={RouterLink}
                  to='/'
                >
                  Back to Portal
                </Button>
              </Stack>
              )}
        </Paper>
      </Stack>
    </Container>
  )
}

export default ResetPassword
