import React, { useEffect } from 'react'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { analyticEventTracker } from 'utils/analytics'
import { currentPageName } from 'hooks/useAnalytics'
import { useLocation } from 'react-router'

export const Prompt = ({ open, onClose, confirmNavigation }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const pageName = currentPageName(location.pathname)

  useEffect(() => {
    if (open) {
      analyticEventTracker('(E) Exit the Register form prompt opened', {
        category: 'Register kit'
      })
    }
  }, [open])
  return (
    <Dialog
      open={open} onClose={onClose} sx={theme => ({
        borderRadius: theme.shape.borderRadius
      })}
    >
      <Stack flexDirection='column' justifyContent='center' sx={{ textAlign: 'center' }} gap={3} padding={theme => theme.spacing(4)}>
        <Typography variant='h5'>{t('registerKit.exitPrompt.title')}</Typography>
        <Typography variant='body1'>{t('registerKit.exitPrompt.description')}</Typography>
        <Stack
          flexDirection='row' justifyContent='space-between' gap={2} sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: 1
            }
          })}
        >
          <Button
            variant='contained'
            onClick={() => {
              analyticEventTracker(`(E) Exited the Register form at ${pageName} and prompt closed`, {
                category: 'Register kit'
              })
              confirmNavigation()
            }}
            fullWidth
          >
            {t('registerKit.exitPrompt.saveProgressBtn')}
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              analyticEventTracker('(E) Exit the Register form prompt closed', {
                category: 'Register kit'
              })
              onClose()
            }}
            fullWidth
          >
            {t('registerKit.exitPrompt.continueBtn')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
