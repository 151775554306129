import jwt from 'jsonwebtoken'
const _ = require('lodash')

export const fetchRoles = () => {
  const token = window.sessionStorage.getItem('token')
  const tokenData = jwt.decode(token)
  return (
    tokenData?.auth?.payload?.[`${process.env.REACT_APP_AUTH0_ACTION_NAMESPACE}/roles`] || []
  )
}

const decodeToken = () => {
  return jwt.decode(window.sessionStorage.getItem('token'))
}

const fetchMetaData = () => {
  const tokenData = decodeToken()
  return tokenData?.auth?.payload?.[`${process.env.REACT_APP_AUTH0_ACTION_NAMESPACE}/meta`]
}

const jwtUtil = {
  getRoles: () => {
    return fetchRoles()
  },
  getFeatureRoles: () => {
    const roles = fetchRoles()
    return roles.filter(role => role.indexOf('feat_') === 0).map(role => role.replace('feat_', ''))
  },
  checkRole: role => {
    const roles = fetchRoles()
    return roles.includes(role)
  },
  checkSpecificRole: role => {
    const roles = fetchRoles()
    return roles.includes(role) && roles.length === 1
  },
  checkSuperAdmin: () => {
    const roles = fetchRoles()
    return ['portal_admin'].some(i => roles.includes(i))
  },
  checkClientAdmin: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'portal_client_admin', 'portal_site_admin'].some(i => roles.includes(i))
  },
  checkOperationsManager: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'operations_manager'].some(i => roles.includes(i))
  },
  checkPharmacist: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'portal_site_admin', 'portal_client_admin', 'portal_pharmacist'].some(i => roles.includes(i))
  },
  checkClientViewer: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'portal_client_admin', 'portal_client_viewer', 'portal_site_admin'].some(
      i => roles.includes(i)
    )
  },
  checkManageAdmin: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'portal_manage'].some(i => roles.includes(i))
  },
  checkHealthUser: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'portal_payer_admin', 'portal_care_manager'].some(i => roles.includes(i))
  },
  checkLabPortal: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'labportalv2_role'].some(i => roles.includes(i))
  },
  checkLabTech: () => {
    const roles = fetchRoles()
    return ['labtech'].some(i => roles.includes(i))
  },
  checkOpsRole: () => {
    const roles = fetchRoles()
    return ['ops_role'].some(i => roles.includes(i))
  },
  checkDemoUser: () => {
    const roles = fetchRoles()
    return ['portal_demo_features'].some(i => roles.includes(i))
  },
  checkSiteOwner: () => {
    const roles = fetchRoles()
    return ['portal_admin', 'portal_site_admin'].some(i => roles.includes(i))
  },
  checkProviderAnalytics: () => {
    const roles = fetchRoles()
    return ['portal_provider_analytics'].some(i => roles.includes(i))
  },
  checkCustomerAdmin: () => {
    const roles = fetchRoles()
    return ['operations_manager', 'client_provider_admin', 'client_provider_viewer', 'portal_admin'].some(i => roles.includes(i))
  },
  check2faBypass: () => {
    const roles = fetchRoles()
    return ['flag_bypass_2fa'].some(i => roles.includes(i))
  },
  checkOnlyClientViewer: () => {
    const roles = fetchRoles()
    return _.isEqual(['client_provider_viewer'], roles)
  },
  checkRegularUser: () => {
    const roles = fetchRoles()
    return roles.length === 0
  },
  checkWhiteLabelOperations: () => {
    const roles = fetchRoles()
    return ['operations_manager', 'portal_admin'].some(i => roles.includes(i))
  },
  getSites: () => {
    const meta = fetchMetaData()
    return meta?.sites || []
  },
  getName: () => {
    const meta = fetchMetaData()
    return { firstName: meta?.firstName, lastName: meta?.lastName }
  },
  getMeta: () => {
    const tokenData = decodeToken()
    return tokenData?.auth?.payload?.sub
  },
  checkSsoUser: () => {
    const meta = fetchMetaData()
    return meta?.sso_login
  },
  isEmailVerified: () => {
    const tokenData = decodeToken()
    return tokenData?.auth?.payload?.[`${process.env.REACT_APP_AUTH0_ACTION_NAMESPACE}/email_verified`]
  },
  getNotificationEmail: () => {
    const meta = fetchMetaData()
    return meta?.notificationEmail
  },
  getLegalInfo: () => {
    const meta = fetchMetaData()
    return {
      tncDate: meta?.tncDate
    }
  },
  getMfaData: () => {
    const meta = fetchMetaData()
    return {
      mfaConfig: meta?.mfaConfig
    }
  }
}

export default jwtUtil
