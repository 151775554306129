import React, { createContext, useContext } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useLocation, Link, useNavigate } from 'react-router-dom'

export type TRoute<TData = unknown> = {
  path: string
  skip?: (data: TData) => boolean
}

type TMultiStepFormContext<TData> = {
  currentStep: number
  totalSteps: number
  update: (data: TData, options?: { skipNavigation: boolean }) => void
  previousRoute: TRoute<TData> | null
  data: TData
}

const MultiStepFormContext = createContext<TMultiStepFormContext<any> | null>(
  null
)
MultiStepFormContext.displayName = 'MultiStepFormContext'

export function MultiStepForm<TData> ({
  children,
  routes,
  data,
  onChange
}: {
  children: React.ReactNode
  routes: TRoute<TData>[]
  data: TData
  onChange: (data: TData) => void
}) {
  const location = useLocation()
  const navigate = useNavigate()

  function isActiveRoute (route: TRoute<TData>) {
    return (
      route.path &&
      route.path !== '/' &&
      location.pathname.includes('/' + route.path.replace('/', ''))
    )
  }

  function getEnabledRoutes (data: TData) {
    return routes.filter(isRouteEnabled(data))
  }

  const enabledRoutes = getEnabledRoutes(data)

  const activeRouteIndex = enabledRoutes.findIndex(isActiveRoute)
  const isIndexRouteActive = activeRouteIndex === -1
  const currentStep = isIndexRouteActive ? 0 : activeRouteIndex

  function update (updatedData: TData, options: { skipNavigation: boolean } = { skipNavigation: false }) {
    onChange(updatedData)
    const nextRoute = getEnabledRoutes(updatedData)[currentStep + 1]
    if (nextRoute && !options.skipNavigation) {
      const routePrefix = isIndexRouteActive ? './' : ''
      navigate(routePrefix + nextRoute.path)
    }
  }

  const previousRoute = enabledRoutes[currentStep - 1]

  const totalSteps = enabledRoutes.length

  const value = {
    data,
    update,
    currentStep,
    totalSteps,
    previousRoute
  }

  return (
    <MultiStepFormContext.Provider value={value}>
      {children}
    </MultiStepFormContext.Provider>
  )
}

function isRouteEnabled<TData> (data: TData) {
  return function (route: TRoute<TData>) {
    return route.skip ? !route.skip(data) : true
  }
}

export function useMultiStepForm<TData> () {
  const context = useContext(MultiStepFormContext)
  if (!context) {
    throw new Error('useMultiStepForm must be in scope with <MultiStepForm />')
  }

  return context as TMultiStepFormContext<TData>
}

export function MultiStepFormNavigation<TData> ({
  back = null,
  next = null
}: {
  back?: React.ReactNode | null
  next?: React.ReactNode | null
}) {
  const { currentStep, totalSteps, previousRoute } = useMultiStepForm<TData>()

  return (
    <MultiStepNavigationBase
      next={
        next || (
          <Button type='submit' variant='contained'>
            Next
          </Button>
        )
      }
      back={
        back || (
          <Button
            component={Link}
            to={previousRoute?.path ? '../' + previousRoute.path : '..'}
            replace
            disabled={currentStep === 0}
          >
            Back
          </Button>
        )
      }
      totalSteps={totalSteps}
      currentStep={currentStep + 1}
    />
  )
}

export function MultiStepNavigationBase ({
  back,
  next,
  totalSteps,
  currentStep
}: {
  back: React.ReactNode
  next: React.ReactNode
  totalSteps: number
  currentStep: number
}) {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {back}
      <Typography variant='body2' sx={{ color: '#00000061', fontWeight: 700 }}>
        Step {currentStep} of {totalSteps}
      </Typography>
      {next}
    </Stack>
  )
}
