import { IconButton, Stack, TextField, Typography } from '@mui/material'
import PasswordPolicy from 'components/PasswordPolicy'
import React, { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useTranslation } from 'react-i18next'

const SignUp = ({ formik, setAuthComponent, showFirstName, showLastName, type, title = null, mt = 2, disableEmail = false }) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(showPassword => !showPassword)

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const toggleShowConfirmPassword = () => setShowConfirmPassword(showPassword => !showPassword)

  return (
    <Stack gap={2} mt={mt}>
      <Typography variant={type === 'page' ? 'h5' : 'h6'} component="h5">
        {t('signup.button.createAccount')}
      </Typography>

      <Typography variant="body1" component="p">
        { title || t('ftp.signupSubtitle')}
      </Typography>

      <Typography
        variant='body2'
        sx={{
          fontWeight: 500,
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
        onClick={() => setAuthComponent('sign-in')}
      >
        {t('signup.alreadyHaveAccount')}
      </Typography>

      {
        showFirstName && (
          <TextField
            id='first-name'
            name='firstName'
            label={t('account.profile.firstName') + ' *'}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            helperText={formik.errors.firstName}
            error={Boolean(formik.errors.firstName)}
          />
        )
      }

      {
        showLastName && (
          <TextField
            id='last-name'
            name='lastName'
            label={t('account.profile.lastName') + ' *'}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            helperText={formik.errors.lastName}
            error={Boolean(formik.errors.lastName)}
          />
        )
      }
      <TextField
        id='email'
        name='email'
        disabled={disableEmail}
        label={t('signup.formfields.emailAddress') + ' *'}
        value={formik.values.email}
        onChange={(event) => {
          event.target.value = event.target.value.trim()
          formik.handleChange(event)
        }}
        helperText={
          formik.touched.email &&
          Boolean(formik.errors.email) &&
          formik.errors.email
        }
        error={formik.touched.email && Boolean(formik.errors.email)}
      />
      { disableEmail
        ? null
        : (
          <TextField
            id='confirmEmail'
            name='confirmEmail'
            label={t('signup.formfields.confirmEmailAddress') + ' *'}
            value={formik.values.confirmEmail}
            onPaste={(e) => e.preventDefault()}
            onChange={(event) => {
              event.target.value = event.target.value.trim()
              formik.handleChange(event)
            }}
            helperText={
              Boolean(formik.errors.confirmEmail) &&
              formik.errors.confirmEmail
            }
            error={Boolean(formik.errors.confirmEmail)}
          />
          )
      }

      <TextField
        id='password'
        name='password'
        label={t('signup.formfields.password') + ' *'}
        type={showPassword ? 'text' : 'password'}
        onPaste={(e) => e.preventDefault()}
        onChange={formik.handleChange}
        InputProps={{
          endAdornment: (
            <IconButton onClick={toggleShowPassword} aria-label='visibility'>
              {showPassword
                ? (
                  <VisibilityOffIcon />
                  )
                : (
                  <VisibilityIcon />
                  )}
            </IconButton>
          )
        }}
        error={
          formik.touched.password && Boolean(formik.errors.password)
        }
        helperText={
          formik.touched.password &&
          Boolean(formik.errors.password) &&
          formik.errors.password
        }
      />

      <TextField
        id='confirm-password'
        name='confirmPassword'
        label={t('signup.formfields.confirmPassword') + ' *'}
        onPaste={(e) => e.preventDefault()}
        type={showConfirmPassword ? 'text' : 'password'}
        onChange={formik.handleChange}
        InputProps={{
          endAdornment: (
            <IconButton onClick={toggleShowConfirmPassword} aria-label='visibility'>
              {showConfirmPassword
                ? (
                  <VisibilityOffIcon />
                  )
                : (
                  <VisibilityIcon />
                  )}
            </IconButton>
          )
        }}
        error={Boolean(formik.errors.confirmPassword)}
        helperText={formik.errors.confirmPassword}
      />

      <PasswordPolicy context={formik.values} />
    </Stack>
  )
}

export default SignUp
